.card {
    max-width: 25rem;
    padding: 0 1rem;
    box-sizing: border-box;
    margin: 2rem;
}

.twoLines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    min-height: 32px;
}

.price {
    font-size: 48px;
    font-weight: 300;
    line-height: 72px;
}

.month {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.planName {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
}

.chooseButton {
    margin: 1rem;
    margin-bottom: 2rem;
    width: 65%;
}

@media (max-width: 450px) {
    .price {
        font-size: 36px;
        font-weight: 300;
        line-height: 48px;
    }
    
    .month {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .chooseButton {
        font-size: .8rem;
    }
}

@media (max-width: 400px) {

    .chooseButton {
        width: 80%;
    }
}