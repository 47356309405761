.videoSearchPanel {  
    position: absolute;
    left: 0;
    right: 50%;
    background-color: #fafafa;
}

.search {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.searchIcon {
    display: none;
}

.appBarShift {
    position: absolute;
    top: 64px;
    height: 100%;
    width: 100%;
}

@media (max-width: 599px) {
    .appBarShift {
        top: 56px;
    }
}

.appBarShiftNone {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

@media (max-width: 1100px) {
    .videoSearchPanel {  
        right: 25%;
    }
}

@media (max-width: 850px) {
    .videoSearchPanel {  
        right: 0%;
    }
}

@media (max-width: 550px) {
    .search {
        display: none;
    }
    
    .searchIcon {
        display: flex;
    }

    .videoSearchPanel {  
        top: 100%;
    }
}