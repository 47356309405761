.demoInfoText { 
    margin-bottom: .5rem;
    font-size: .8rem;
}

.heading { 
    border-bottom: 2px solid #ccc;
    max-width: 400px;
    width: 75%;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center; 
}