.textTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 1rem;
}

.textDate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.textDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.ownerNameText {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.ownerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.textMoreFrom {
    margin: 2rem 0;
}

@media (max-width: 800px) {

    .textTitle {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }

}

@media (max-width: 600px) {

    .textTitle {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }

    .textDate {
        font-size: 14px;
        line-height: 16px;
    }

    .textDescription {
        font-size: 14px;
        line-height: 16px;
    }
    
    .ownerNameText {
        font-size: 14px;
        line-height: 18px;
    }

    .textMoreFrom {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
}

@media (max-width: 500px) {

    .textTitle {
        font-size: 1.15rem;
        line-height: 1.3rem;
    }

    .textDate {
        font-size: 12px;
        line-height: 14px;
    }

    .textDescription {
        font-size: 13px;
        line-height: 15px;
    }

}

@media (max-width: 450px) {

    .ownerInfo {
        flex-direction: column;
        align-items: flex-start;
    }

    .followButton {
        margin-top: .5rem;
        margin-left: -8px;
    }

    .textMoreFrom {
        font-size: 1rem;
        line-height: 1.1rem;
    }

    .textDescription {
        font-size: 12px;
        line-height: 14px;
    }
}

@media (max-width: 400px) {

    .textTitle {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}