.pageHeader {
    background-image: linear-gradient(to bottom right, blue, purple); 
    width: 100%; 
    height: 66vh;
}

.pageHeaderBar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.homeButton {
    margin: 1rem;
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
}

.loginButton {
    margin: 1rem;
    color: #fff;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.headerText {
    font-size: 2.5rem;
    font-weight: 200;
    line-height: 3rem;
    color: #FFF;
    margin: 1rem;
}

.headerMain {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signUpButton {
    background-color: #006666;
    color: #fff;
}

.subCardSection {
    display: flex;
    justify-content: center;
}

@media (max-width: 800px) {
    .subCardSection {
        flex-direction: column;
        align-items: center;
    }

    .headerText {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

@media (max-width: 650px) {
    .headerText {
        font-size: 1.7rem;
        line-height: 2rem;
    }
}

@media (max-width: 550px) {
    .headerText {
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
}

@media (max-width: 550px) {
    .headerText {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
    }
}