.headingText {
    margin: 1rem;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
}

@media (max-width: 400px) {
    .headingText {
        font-size: 1.3rem;
    }
}