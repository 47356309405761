.dialog {
    width: 100%;
    margin: 3rem;
    overflow-x: auto;
}

@media (max-width: 500px) {

    .dialog {
        margin: 1rem;
    }
}