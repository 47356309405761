.subCardSection {
    display: flex;
    justify-content: center;
}

.pageHeaderBar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.homeButton {
    margin: 1rem;
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    cursor: pointer;
}

.loginButton {
    margin: 1rem;
    color: #fff;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.pageHeader {
    background-image: linear-gradient(to bottom right, blue, purple); 
    width: 100%; 
    height: 20vh;
}

@media (max-width: 800px) {
    .subCardSection {
        flex-direction: column;
        align-items: center;
    }
}