.plotRow {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1300px) {
    .plotRow {
        flex-direction: column;
    }
}