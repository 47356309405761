.page {
    padding: 3rem;
    position: relative;
}

.settingName {
    font-weight: 500;
    width: 33%;
}

.settingItem {
    display: flex;
    margin-bottom: .5rem;
    flex-direction: row;
}

@media (max-width: 900px) {
    .settingItem {
        flex-direction: column;
    }

    .settingValue {
        margin-left: 1rem;
        margin-top: .5rem;
        margin-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .settingName {
        width: 100%;
    }
}

@media (max-width: 500px) {

    .page {
        padding: 1rem;
    }
}