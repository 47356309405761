@media (max-width: 650px) {
    .disappear1 {  
        display: none;
    }
}

@media (max-width: 500px) {
    .disappear2 {  
        display: none;
    }
}