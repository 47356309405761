.Front {
    position: absolute;
    top: 0; 
    left: 0;
    color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: all .5s;
    cursor: pointer;
    opacity: 0;
    color: white;
}

.Avatar:hover .Front {
    opacity: 1;
}

.dialog {
    width: 100%;
    margin: 3rem;
    overflow-x: auto;
}

@media (max-width: 500px) {

    .dialog {
        margin: 1rem;
    }
}